import {Box, FormControlLabel, FormGroup, IconButton, ImageListItemBar, Switch, useTheme} from "@mui/material";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { MPContext } from "../../MPContext";
import { Bio } from "./Bio";
import { Photo, PhotoList } from "../../service/types";
import { MPPhotoGrid } from "../../components/MPPhotoGrid";
import { LockOpen, Lock } from "@mui/icons-material";
import { usePhotoService } from "../../service/mphotoservice";

export function HomeRoute() {
  const ps = usePhotoService();
  const [photos, setPhotos] = useState<PhotoList>({ length: 0, photos: [] });
  const [psIds, setPsIds] = useState<Set<string>>(new Set<string>())
  const [photoStream, setShowPhotoStream] = useState(false)
  const theme = useTheme();
  const context = useContext(MPContext);

  useEffect(() => {
    const retrieve = async () => {
      let pl: PhotoList = {length: 0, photos: []}
      let pls : PhotoList = {length: 0, photos: []}
      if(context.uxConfig.photoStreamAlbumId != '') {
        pls = await ps.getAlbumPhotos(context.uxConfig.photoStreamAlbumId)
      }
      if(context.isUser && !photoStream) {
        pl = await ps.getPhotos()

      } else {
        pl = pls
      }
      //get photo stream album photos
      //create a set of photostream photo ids
      let psids = new Set<string> ()
      pls.photos.forEach(e => psids.add(e.id))
      setPhotos(pl)
      setPsIds(psids)
    }
    retrieve()
  }, [context.uxConfig.photoStreamAlbumId, context.isUser, ps, photoStream]);

  function onToggleVisiblity(p: Photo) {
    if(context.uxConfig.photoStreamAlbumId == '') {
      alert("No PhotoStream album set")
      return
    }
    if(psIds.has(p.id)) {
      ps.deleteAlbumPhotos(context.uxConfig.photoStreamAlbumId, [p.id])
        .then(ai => {
          const newPsIds = new Set(psIds)
          newPsIds.delete(p.id)
          setPsIds(newPsIds)
        }).catch(e => alert(e))
    } else {
      ps.addAlbumPhotos(context.uxConfig.photoStreamAlbumId, [p.id])
        .then(ai => {
          const newPsIds = new Set(psIds)
          newPsIds.add(p.id)
          setPsIds(newPsIds)
        }).catch(e => alert(e))
    }

    /*
    ps.togglePrivate(p.id).then((res) => {
      const newPhotos = photos.photos.map((photo) => {
        if (photo.id === res.id) {
          return res;
        } else return photo;
      });
      const newPL: PhotoList = { photos: newPhotos, length: newPhotos.length };
      setPhotos(newPL);
    });*/
  }

  function dimPhoto(p: Photo): boolean {
    return psIds.has(p.id) ? false : true
  }

  function getItemBar(p: Photo, idx: number): ReactNode {
    if (!context.isUser) {
      return null;
    }
    return (
      <ImageListItemBar
        actionIcon={
          <>
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 0.70)" }}
              aria-label={"Toggle Private/Public"}
              onClick={() => onToggleVisiblity(p)}
            >
              {psIds.has(p.id) ? <LockOpen /> : <Lock/>}
            </IconButton>
          </>
        }
      />
    );
  }

  const handleShowPhotoStream = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPhotoStream(event.target.checked);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        paddingLeft: 0,
        maxWidth: 1024,
        margin: "auto",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
      }}
    >
      {context.uxConfig.showBio && <Bio />}
      {context.isUser &&
        <FormGroup>
          <FormControlLabel
            control={<Switch onChange={handleShowPhotoStream}/>} label={"Show Photostream"}/>
        </FormGroup>

      }

      {photos.length > 0 && (
        <MPPhotoGrid
          columns={context.uxConfig.photoGridCols}
          linkTo={"/photo/"}
          spacing={context.uxConfig.photoGridSpacing}
          items={photos.photos}
          dimPhoto={dimPhoto}
          itemListBar={getItemBar}
        />
      )}
    </Box>
  );
}
